import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ProductsLarge from '../../components/ProductsLarge'

const ProductsTransportIntermodalny = ({ data, minimal }) => {
  const query = useStaticQuery(graphql`
    {
      road: file(
        absolutePath: {
          regex: "/images/products/transport-intermodalny/road.png/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
      rail: file(
        absolutePath: {
          regex: "/images/products/transport-intermodalny/rail.png/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
      meritime: file(
        absolutePath: {
          regex: "/images/products/transport-intermodalny/meritime.png/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
    }
  `)

  return <ProductsLarge query={query} data={data} minimal={minimal} />
}

export default ProductsTransportIntermodalny
