import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Stages from '../../components/Stages'

const StagesTransportIntermodalny = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/stages/transport-intermodalny/1.jpeg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/stages/transport-intermodalny/2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/stages/transport-intermodalny/3.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/stages/transport-intermodalny/4.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/stages/transport-intermodalny/5.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return <Stages query={query} data={data} />
}

export default StagesTransportIntermodalny
