import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section'
import { s } from '../style'

const AdditionalText = ({ text }) => {

  return (
    <Section bottomSmall>
      <article
        css={[sAdditionalText]}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Section>
  )
}

export default AdditionalText

const sAdditionalText = {
  margin: '3rem 0 2rem',
  [s.xs]: {
    margin: '2rem 0 0',
  },
  h2: {
    fontSize: '1.5rem',
    padding: '2.5rem 0 1.5rem',
    margin: '0',
    ':first-of-type': {
      paddingTop: '0',
    },
  },
  h3: {
    fontSize: '1.25rem',
    padding: '1.5rem 0',
    margin: '0',
  },
  p: {
    fontSize: '1rem',
    lineHeight: '2em',
    marginBottom: '1.5rem',
    position: 'relative',
    ':last-of-type': {
      marginBottom: '0',
    },
  },
  'p + p': {
    margin: '1.25rem 0 0',
  },
  'ol, ul': {
    margin: '0 0 1.5rem 2.5rem',
    lineHeight: '2rem',
  },
  'ol li, ul li': {
    paddingBottom: '.5rem',
  },
  'p + ol, p + ul': {
    marginTop: '1.5rem',
  },
  '*>img': {
    display: 'block',
    width: '100%',
    objectFit: 'cover',
    maxHeight: 'auto',
    margin: '2rem auto',
    borderRadius: '1.5rem',
    // [s.lg]: {
    //   maxHeight: '832px',
    // },
  },
  '.gif-image img': {
    maxWidth: 'max-content',
  },
  iframe: {
    width: '100%',
    maxWidth: 960,
    height: 540,
    maxHeight: 540,
    borderRadius: '1.5rem',
    overflow: 'hidden',
    transform: 'translateZ(0)',
    backdropVisibility: 'hidden',
  
    [s.sm_down]: {
      borderRadius: '1rem',
      marginBottom: '1rem',
      height: 'calc(9 * (100vw - 4rem) / 16)',
    },
  },
  a: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    ':hover': {
      textDecoration: 'none',
    },
  },
}
